import React from "react";
import Fade from "react-reveal/Fade";
import { Container } from "../../global";
import { Text } from "rebass/styled-components";

import { StyledBackgroundSection, HeaderWrapper, HeaderTextGroup, Flex } from "./style";

import HeroSection from "../hero";

const Header = () => {
  return (
    <StyledBackgroundSection>
      <HeaderWrapper>
        <Container>
          <Flex>
            <HeaderTextGroup>
              <Fade bottom delay={250}>
                <HeroSection.Title>Contact Us</HeroSection.Title>
              </Fade>
              <Fade bottom delay={500}>
                <Text
                  as="p"
                  fontSize={["md", null, null, "lg", "lg"]}
                  color="header"
                  mb="3rem"
                  lineHeight="1.5"
                  textAlign="center"
                >
                  OPay gives you the freedom to make quick and easy payments, earn money, spend smart, and save more.
                </Text>
              </Fade>
            </HeaderTextGroup>
          </Flex>
        </Container>
      </HeaderWrapper>
    </StyledBackgroundSection>
  );
};

export default Header;
