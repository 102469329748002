import React from "react";
import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";
import Header from "../../components/sections/contact-us/header";
import Inquiries from "../../components/sections/home/inquiries";
import Footer from "../../components/sections/footer";
import ContactDetails from "../../components/sections/contact-us";

const ContactUs = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Navigation background="white" />
      <Header />
      <ContactDetails />
      <Inquiries />
      <Footer />
    </Layout>
  );
};

export default ContactUs;
