import React from "react";
import { Heading, Box, Text, Flex } from "rebass/styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useLocationCountry } from "../../../../hooks";
import FacebookIcon from "../../../../images/social-media/facebook.svg";
import InstagramIcon from "../../../../images/social-media/instagram.svg";
import LinkedinIcon from "../../../../images/social-media/linkedin.svg";
import TwitterIcon from "../../../../images/social-media/twitter.svg";

import { Wrapper, SocialLinks, SocialLink, SocialItem, SectionWrapper, TelAndMail } from "./style";

const linkProps = {
  as: "a",
  color: "secondary",
  fontWeight: "semibold",
};

const Inquiries = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          playstoreUrl
          applestoreUrl
          blogUrl
          facebookUrl
          twitterUrl
          instagramUrl
          linkedinUrl
          documentationUrl
        }
      }
    }
  `);

  const { isPakistan } = useLocationCountry();

  return (
    <SectionWrapper>
      {!isPakistan ? (
        <Wrapper>
          <Box
            m={"0 auto"}
            px="2rem"
            width="100%"
            maxWidth={["100%", "54rem", "72rem", "96rem", "120rem"]}
            py={["6rem", null, "16rem"]}
          >
            <Flex alignItems="flex-start" flexDirection="column" maxWidth="80rem">
              <Heading mb="2.8rem" as="h3">
                Do you still have questions?
              </Heading>

              <Text variant="body" as="p" textAlign="start" mb="3rem" maxWidth="80%">
                Check out our{" "}
                <Link to="/faqs" style={{ textDecoration: "none", fontWeight: "600", color: "#1fc69d" }}>
                  FAQ
                </Link>{" "}
                page to find answers to questions about our products and services. <br /> Need more help? Reach out to
                us via Social media, Telephone or Email. <br /> Our support team is always available to assist you.{" "}
                <br />
                <br />
                <TelAndMail>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                  <Text
                    href="tel:+23407008888328"
                    sx={{
                      textDecoration: "none",
                    }}
                    style={{ color: "#1fc69d" }}
                    {...linkProps}
                  >
                    0700 8888 328
                  </Text>{" "}
                  OR{" "}
                  <Text
                    href="tel:02018888328"
                    sx={{
                      textDecoration: "none",
                    }}
                    style={{ color: "#1fc69d" }}
                    {...linkProps}
                  >
                    020 18888 328
                  </Text>{" "}
                  <Text as="span">(App & Card queries)</Text>{" "}
                </TelAndMail>
                <TelAndMail>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                  <Text
                    href="tel:+23407008888329"
                    sx={{
                      textDecoration: "none",
                    }}
                    style={{ color: "#1fc69d" }}
                    {...linkProps}
                  >
                    0700 8888 329
                  </Text>{" "}
                  OR{" "}
                  <Text
                    href="tel:02018888329"
                    sx={{
                      textDecoration: "none",
                    }}
                    style={{ color: "#1fc69d" }}
                    {...linkProps}
                  >
                    020 18888 329
                  </Text>{" "}
                  <Text as="span">(POS Business queries)</Text>
                </TelAndMail>
                {/* <TelAndMail>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                  </svg>
                  <Text
                    href="mailto:customerservice@opay-inc.com"
                    sx={{
                      textDecoration: "none",
                    }}
                    style={{ color: "#1fc69d" }}
                    {...linkProps}
                  >
                    customerservice@opay-inc.com
                  </Text>{" "}
                </TelAndMail> */}
              </Text>

              <SocialLinks as="ul">
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.facebookUrl} aria-label="Opay facebook link">
                    <FacebookIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.twitterUrl} aria-label="Opay twitter link">
                    <TwitterIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.instagramUrl} aria-label="Opay instagram link">
                    <InstagramIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.linkedinUrl} aria-label="Opay linkedin link">
                    <LinkedinIcon />
                  </SocialLink>
                </SocialItem>
              </SocialLinks>
            </Flex>
          </Box>
        </Wrapper>
      ) : (
        <Wrapper pk>
          <Box
            m={"0 auto"}
            px="2rem"
            width="100%"
            maxWidth={["100%", "54rem", "72rem", "96rem", "120rem"]}
            py={["6rem", null, "16rem"]}
          >
            <Flex alignItems="flex-start" flexDirection="column" maxWidth="80rem">
              <Heading mb="2.8rem" as="h3">
                Do you still have questions?
              </Heading>

              <Text variant="body" as="p" textAlign="start" mb="3rem" maxWidth="80%">
                Check out our{" "}
                <Link to="/faqs" style={{ textDecoration: "none", fontWeight: "600", color: "#1fc69d" }}>
                  FAQ
                </Link>{" "}
                page to answer any of your questions <br /> around our products and services, and if you require further
                assistance or would like to reach us for general inquiries, <br /> please email us &nbsp;
                <Text
                  href="mailto:info@opay-inc.com"
                  sx={{
                    textDecoration: "none",
                  }}
                  {...linkProps}
                >
                  info@opay-inc.com
                </Text>{" "}
              </Text>

              <SocialLinks as="ul">
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.facebookUrl} aria-label="Opay facebook link">
                    <FacebookIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.twitterUrl} aria-label="Opay twitter link">
                    <TwitterIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.instagramUrl} aria-label="Opay instagram link">
                    <InstagramIcon />
                  </SocialLink>
                </SocialItem>
                <SocialItem>
                  <SocialLink href={data.site.siteMetadata.linkedinUrl} aria-label="Opay linkedin link">
                    <LinkedinIcon />
                  </SocialLink>
                </SocialItem>
              </SocialLinks>
            </Flex>
          </Box>
        </Wrapper>
      )}
    </SectionWrapper>
  );
};

export default Inquiries;
